import React, { useMemo } from 'react';
import { Alert, Card, Divider, Input, Layout, Row, Space } from 'antd';
import { Helmet } from 'react-helmet';
import { QuickForm } from '../components/QuickForm';
import { PageProps } from 'gatsby';
import getRenders from '../utils/getRenders';
import { UserOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import useBeFn from '../modules/app/useBeFn';
import config from '../modules/dynamic-config';

export type FormValues = {
  code: string;
  password: string;
};

/**
 * Renderer
 */
const { formItems, buttons } = getRenders({
  formItems: {
    code: {
      name: 'code',
      children: (
        <Input placeholder="Verify code" readOnly disabled size="large" />
      ),
    },
    password: {
      name: 'password',
      rules: [
        { required: true, message: 'Password required!' },
        { min: 6, message: 'Required strong password' },
      ],
      children: <Input.Password size="large" placeholder="New password" />,
    },
  },
  buttons: {
    submit: {
      htmlType: 'submit',
      children: 'Submit',
      type: 'primary',
      block: true,
      size: 'large',
    },
  },
});

export default function VerifyCodeAction(props: PageProps) {
  const params = new URLSearchParams(props.location.search);
  const code = params.get('code');
  const hash = params.get('hash');
  const action = params.get('action') || 'ForgotPassword';

  const pageTitle = useMemo(() => {
    if (action === 'ForgotPassword') {
      return 'Reset your password';
    }
  }, [action]);

  // Form initial values
  const initialValues = useMemo(() => {
    return { code };
  }, [code]);

  const [{ loading: loginLoading, data, error: loginError }, resetPassword] =
    useBeFn('verifyCode/resetPassword');

  const errorMessage = useMemo(() => {
    return loginError ? loginError.message || 'Unknown' : null;
  }, [loginError]);

  const submit = (value: FormValues) => {
    if (!code || !hash) {
      return;
    }
    return resetPassword({
      code: value.code,
      hash,
      password: value.password,
    });
  };

  return (
    <Layout
      style={{
        minHeight: '100vh',
        backgroundColor: '#ececec',
      }}
    >
      <Helmet title={pageTitle}>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <Layout.Content
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Card
          style={{ width: 360 }}
          title={
            <Space>
              <UserOutlined /> {config.siteTitle} - {pageTitle}
            </Space>
          }
        >
          {errorMessage && (
            <Alert
              showIcon
              message={errorMessage}
              type="error"
              closable
              style={{ marginBottom: 24 }}
            />
          )}
          {data?.success && (
            <Alert
              showIcon
              message="Your password changed!"
              type="success"
              style={{ marginBottom: 24 }}
            />
          )}
          {!data?.success && (
            <QuickForm<FormValues>
              labelCol={{ span: 0 }}
              wrapperCol={{ span: 24 }}
              layout="vertical"
              initialValues={initialValues}
              onFinish={submit}
            >
              {formItems.code()}
              {formItems.password()}
              <Divider />
              {buttons.submit({
                loading: loginLoading,
              })}
            </QuickForm>
          )}
        </Card>
      </Layout.Content>
      <Layout.Footer style={{ background: 'none' }}>
        <Row justify="center">
          <Text type="secondary">
            <small>xAdmin version 1.0</small>
          </Text>
        </Row>
      </Layout.Footer>
    </Layout>
  );
}
